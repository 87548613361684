
// jquery is required for bootstrap 3
import { $, jQuery } from './import-jquery';

// bootstrap
import collapse from 'bootstrap';

// chart.js
import Chart from 'chart.js/auto';
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';

// fullcalendar
import { Calendar } from 'fullcalendar-scheduler';

// hack for moment (used in conference_schedule)
import moment from 'moment';
window.moment = moment;

// previously pages/index.js
window.onscroll = () => {
    document.body.classList.toggle("mastheadopen", !(document.body.scrollTop > 20 || document.documentElement.scrollTop > 20));
};


const onReady = (callback) =>{
  if (document.readyState!='loading') callback();
  else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
  else document.attachEvent('onreadystatechange', function() {
    if (document.readyState=='complete') callback();
  });
};

onReady(() => {
  const elem = document.getElementById("price_chart");
  if(elem)
  {
    const data = JSON.parse(document.getElementById("prices_chart_data").textContent);
    if(data.length) {
      Chart.defaults.scales.linear.min = 0;
      new Chart(
        elem,
        {
          type: 'line',
          data: {
            datasets: [
              {
                label: 'Price',
                data: data,
              }
            ]
          },
          options: {
            parsing: {
              xAxisKey: 'date',
              yAxisKey: 'value',
            },
            plugins: {
              title: {
                text: 'Price',
                display: true,
              },
              legend: {
                display: false,
              },
              tooltip: {
                interaction: {
                  mode: "point",
                },
                callbacks: {
                  title: (context) => {
                    return "";
                  },
                  label: (context) => {
                    return context.raw.label;
                  },
                },
              },
            },
            scales: {
              x: {
                type: 'time',
                title: {
                  display: true,
                  text: 'Date'
                }
              },
              y: {
                title: {
                  display: true,
                  text: 'Price'
                }
              }
            },
          },
        }
      );
    }
  }

  // geocode
  const geocode_form = document.querySelector("form#geocode");
  if(geocode_form) {
    geocode_form.addEventListener("formdata", (event) => {
      var i = 1;
      while(true) {
        if(!event.formData.has("location-"+i))
        {
          break;
        }
        if(!event.formData.get("latlon-"+i))
        {
          event.formData.delete("latlon-"+i);
          event.formData.delete("location-"+i);
        }
        i++;
      }
    });
  }
  document.querySelectorAll("button.geocode").forEach(btn => {
    btn.addEventListener("click", () => {
      var params = new URLSearchParams({
          q: btn.dataset.location,
          format: "jsonv2",
      });
      fetch('https://nominatim.openstreetmap.org/search?' + params)
        .then(response => response.json())
        .then(data => {
          if(data.length) {
            var idx = btn.dataset.idx;
            var input = document.getElementsByName("latlon-" + idx)[0];
            input.value = data[0].lat + "," + data[0].lon;
          }
        });
    });
  });

});
